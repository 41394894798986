<template>
  <div class="ma-2">
    <v-row>
      <v-col
        v-for="(item, index) in reversedHistory"
        :key="index"
        cols="12"
        sm="6"
        class="item-wrap d-flex justify-space-between align-center px-6 py-4 mb-2"
        @click="navigatePush('OrderHistoryDetails', { orderId: item.orderId })"
      >
        <div class="font-1dot2r font-weight-medium">Order #{{ item.seq }}</div>
        <div class="font-1dot1r">{{ item.cdate ? setTimeZone(item.cdate, 'YYYY-MM-DD, hh:mm A') : item.time }}</div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'OrderHistoryList',
  data() {
    return {
      orderHistory: {},
    };
  },
  computed: {
    ...mapState({
      timeFormat: state => state.app.timeFormat,
    }),
    ...mapGetters(['getOrderHistory']),
    reversedHistory() {
      let orderHistory = JSON.parse(JSON.stringify(this.getOrderHistory));
      return orderHistory.reverse();
    },
  },
  created() {
    this.SET_DRAWER_TITLE('History');
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
  },
};
</script>
<style lang="scss" scoped>
.item-wrap {
  background-color: #fff;
  border-radius: 5px;
}
</style>
